/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useLangKey } from '../../utils/lang';
import { to_locale_data } from '../../utils/date';

export const Date = ({ node }) => {
	const langKey = useLangKey();
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	return <div sx={{ color: '#949494' }}>{to_locale_data(node.date, langKey, options)}</div>;
};
