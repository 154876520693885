/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { useLangKey } from '../../utils/lang';
import { useSetHover } from '../MoreHoverContext';

export const PostLink = ({ node, children, msx }) => {
	const langKey = useLangKey();
	const setHover = useSetHover();
	return (
		<Link
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			sx={{ textDecoration: 'none', color: 'inherit', ':visited': { color: 'inherit' }, ...msx }}
			to={`/${langKey}/blog/${node.postIntl.slug}`}
		>
			{children}
		</Link>
	);
};
