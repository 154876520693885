/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { Themed } from 'theme-ui';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SanityContent from '../content/sanity-content';
//import { FlowerFaceComp } from '../home-page/faces';
import { ShowMemberContextProvider} from '../playinfo-page/ShowMemberContext';
import {Overlay} from '../playinfo-page/playinfo-query'

import { useLangKey } from '../../utils/lang';
import { OtherPosts } from '../post-list-page/OtherPosts'
import ContentContainer from '../../gatsby-theme-catalyst-core/components/content-container';

const red = '#ff2fac';

const redShadow = {
	textShadow: `0 -1px 0 ${red}, 0 1px 0 ${red}, 1px 0 0 ${red}, -1px 0 0 ${red}, -3px 1px 0 ${red}`,
	color: 'white'
};

const PostPage = ({ location, pageContext, data }) => {
	const title = data.sanityPost.postIntl.name;
	const image = data.sanityPost.image?.asset;
	const { intlContent } = data.sanityPost.postIntl;
	const other = data.allSanityPost.nodes
	//const handleClick = useLetBlink()
	return (
		<Layout pathname={location.pathname} pageContext={pageContext}>
			<ContentContainer>
				<ShowMemberContextProvider>
					<div sx={{ maxWidth: '800px', mx: 'auto' }}>
						<Overlay></Overlay>
						<Themed.h2 sx={{ variant: 'text.headingBlog' }}>{title}</Themed.h2>
						{image && <GatsbyImage sx={{ mt: 0, mb: 4 }} image={getImage(image)} />}

						{intlContent && <SanityContent intlContent={intlContent} />}
						<OtherPosts nodes={other} />
					</div>
				</ShowMemberContextProvider>
			</ContentContainer>
		</Layout>
	);
};

export const query = graphql`
	query MyPostQuery($id: String!, $langCode: String!) {
		sanityPost(id: { eq: $id }) {
			image {
				asset {
					gatsbyImageData
				}
			}
			postIntl {
				name(langCode: $langCode)
				intlContent(langCode: $langCode) {
					raw
					rawDe
					rawFr
					rawEs
					rawEn
				}
			}
		}
		allSanityPost(filter: { id: { ne: $id } }) {
			nodes {
				date
				postIntl {
					name(langCode: $langCode)
					slug(langCode: $langCode)
				}
			}
		}
	}
`;

export default PostPage;
