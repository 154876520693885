/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { Themed } from 'theme-ui';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import React, { useState } from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import SanityContent from '../content/sanity-content';
import { ShowMemberContextProvider, useShowMember, useMemberState } from './ShowMemberContext';
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { useSanityConfig } from '../../utils/useSanityConfig'
//import { FlowerFaceComp } from '../home-page/faces';
import { useLangKey } from '../../utils/lang'
import { TermineDates } from '../termine-page/TermineDates'
import { FlowerHeading } from '../FlowerHeading';
import { MemberLink } from './MemberLink';
import ContentContainer from '../../gatsby-theme-catalyst-core/components/content-container';

const red = '#ff2fac';

const redShadow = {
	textShadow: `0 -1px 0 ${red}, 0 1px 0 ${red}, 1px 0 0 ${red}, -1px 0 0 ${red}, -3px 1px 0 ${red}`,
	color: 'white'
};

const FlowerFaceComp = ({ face, flower }) => {
	const showMember = useShowMember()
	const [enter, setEnter] = useState(false);


	//const top_style = enter ? {zIndex:100} : {};
	const style = enter
		? {
			transform: 'scale(1.5)'
		}
		: {
			transform: 'scale(1)',
			transitionDuration: "500ms",
		};
	const style2 = enter
		? {
			transform: 'scale(1.1)'
		}
		: {
			transform: 'scale(1)',
			transitionDuration: "500ms",
		};

	return (
		<div onClick={() => showMember(false)} sx={{ position: 'fixed', top: '0px', left: '0px', background: '#00000078', width: '100%', height: '100%', zIndex: '1000' }}>
			<div
				sx={{
					width: '400px', display: 'grid', position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
				onMouseOver={() => setEnter(true)}
				onMouseOut={() => setEnter(false)}
			>
				<div
					sx={{
						transition: 'transform 1.5s ease-in',
						...style, //...animation,
						gridColumn: 1,
						gridRow: 1
					}}
				>
					<GatsbyImage image={flower} alt={''} />
				</div>

				<div
					sx={{
						//	...ani_tiny,
						transition: 'transform 1.5s ease-in',
						...style2,
						gridColumn: 1,
						gridRow: 1
					}}
				>
					<GatsbyImage image={face} alt={''} />
				</div>
			</div></div>
	);
};

const PlayInfoPage = ({ location, pageContext, data }) => {
	const title = data.sanityPlay.playIntl.name;
	const image = data.sanityPlay.image?.asset;
	const { rawDsc } = data.sanityPlay.playIntl;
	const dates = data.allSanityDatesPage.nodes[0]?.datesByPlayId
	//const handleClick = useLetBlink()
	return (
		<ShowMemberContextProvider>
			<Layout pathname={location.pathname} pageContext={pageContext}>
				<ContentContainer>
					<div sx={{ maxWidth: '800px', mx: 'auto' }}>
						<Themed.h2 sx={{ variant: 'text.headingBlog' }}>{title}</Themed.h2>
						{image && <GatsbyImage sx={{ mt: 0, mb: 4 }} image={getImage(image)} />}
						<Test data={data} />
						{rawDsc && <SanityContent intlContent={rawDsc} />}
					</div>

					{dates && dates.length > 0 && (<div sx={{ maxWidth: '1100px', mx: 'auto', mt: 6 }}>
						{/* <Themed.h2 sx={{mb:6}}>Termine</Themed.h2> */}
						<FlowerHeading title="Termine" msx={{ fontSize: 6 }} staticImage={<StaticImage src="Mishaa_695_blume.png" placeholder="none" sx={{ 'filter': 'saturate(0.5)', width: '270px' }} />} />
						<div sx={{ display: 'grid', gridTemplateColumns: ['auto', null, 'auto auto auto'], maxWidth: '1100px', mx: 'auto', mt: 4 }}>


							{dates.map((date) => {
								return <React.Fragment>
									<Themed.h2
										sx={{ gridColumn: [1, null, 'span 3'], mb: 3, mt: 5, display: "inline", lineHeight: "1.5em", }}
									>
										{date.theater}
									</Themed.h2>
									<TermineDates black={true} date={date} />
								</React.Fragment>;
							})}
						</div>
					</div>)}
				</ContentContainer>
			</Layout>
		</ShowMemberContextProvider>
	);
};

const Test = ({ data }) => (
	<React.Fragment>
		<With data={data} />
		<Overlay />
	</React.Fragment>

)


const intlWith = { de: 'Mit', fr: 'Avec', es: 'Con', en: 'With' }

const With = ({ data }) => {
	const langKey = useLangKey()
	return data.sanityPlay.with?.length >= 1 ?
		(<div>{intlWith[langKey]}: {data.sanityPlay.with.map((member) => <MemberLink member={member} >{member.fullName},{' '}</MemberLink>)}</div>) :
		<React.Fragment />;
};

export const Overlay = () => {
	const { sanityProjectId: projectId, sanityDataset: dataset } = useSanityConfig()
	const sanityConfig = { projectId, dataset }
	const member = useMemberState();
	if (!member.content) return <React.Fragment></React.Fragment>;
	const flower = member.content.flower.asset.gatsbyImageData ? getImage(member.content.flower.asset) : getGatsbyImageData(member.content.flower.asset._ref, { maxWidth: 300 }, sanityConfig);
	const face = member.content.face.asset.gatsbyImageData ? getImage(member.content.face.asset) : getGatsbyImageData(member.content.face.asset._ref, { maxWidth: 300 }, sanityConfig);
	return <FlowerFaceComp flower={flower} face={face} />;

};

export const query = graphql`
	query PlayQuery($id: String!, $langCode: String!) {
		sanityPlay(id: { eq: $id }) {
			playIntl {
				name(langCode: $langCode)
				rawDsc(langCode: $langCode) {
					raw
					rawDe
					rawFr
					rawEs
					rawEn
				}
			}
			image {
				asset {
					gatsbyImageData
				}
			}
			with {
				fullName
				bioSlug
				content {
					flower {
						asset {
							gatsbyImageData
						}
					}
					face {
						asset {
							gatsbyImageData
						}
					}
				}
			}
		}
		allSanityDatesPage {
   		 	nodes {
    			datesByPlayId(id: $id) {
					play {
						playIntl {
							name(langCode: $langCode)
							slug(langCode: $langCode)
						}
					}
					ticketUrl
					note {
						note(langCode: $langCode)
					}
					launchList {
						launchAt
						hint {
							note(langCode: $langCode)
						}
					}
					theater
					location
					datesAreFollowing
				}
			}
		}
	}
`;

export default PlayInfoPage;
