const get_by_langKey = (data, field, langKey) => data[`${langKey}_${field}`];

const langKey_to_dateCode = (langKey) => {
	const codes = { es: 'es-ES', fr: 'fr-FR', en: 'en-US', de: 'de-DE' };
	return codes[langKey];
};

export const to_locale_data = (str,langKey, options) => {
	return new Date(str).toLocaleDateString(langKey_to_dateCode(langKey), options);
};
