/** @jsx jsx */
import { jsx, Themed } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { MoreHoverContextProvider } from '../MoreHoverContext';
import { PostLink } from './PostLink';
import {Date} from './Date'

export function OtherPosts({ nodes }) {
	return (
		<React.Fragment>
			<div sx={{ textAlign: 'center', position: 'relative', my: 6 }}>
				<StaticImage sx={{ width: '150px' }} src="./more_flower.png" />
				<div
					sx={{
						position: 'absolute',
						top: '40%',
						left: '50%',
						width: '100%',
						fontFamily: 'Oswald',
						fontStyle: 'normal',
						fontWeight: 'bold',
						fontSize: 54,
						lineHeight: '100%',
						transform: 'translate(-50%, -50%)',
						fontSize: 7,
						textShadow: '4px 5px 0px #ffffff',
						color: '#ff2fac',
						letterSpacing: '0.07em',
						// backgroundColor: '#ffffff3d',
						/* padding:20, */
						paddingBottom: 30,
						paddingTop: 20
					}}
				>
					...
				</div>
			</div>
			<div sx={{ maxWidth: 600, mx: 'auto' }}>
				{nodes.map((node) => (
					<MoreHoverContextProvider>
						<Rest node={node} />
					</MoreHoverContextProvider>
				))}
			</div>
		</React.Fragment>
	);
}

const Rest = ({ node }) => {
	return (
		<div sx={{ mb: 3 }}>
			<PostLink node={node}>
				<Themed.h1
					sx={{
						mt: '-5px',
						fontSize: 5,
						color: '#00f',
						gridRow: '1',
						gridColumn: '2',
						fontWeight: 'bold',
						fontSize: 32,
						mb: 0,
						':hover': {
							color: '#ff6c3f'
						}
					}}
				>
					{node.postIntl.name}
				</Themed.h1>
				<Date node={node} />
			</PostLink>
		</div>
	);
};
