/** @jsx jsx */
import { jsx } from 'theme-ui';
import { to_locale_data } from '../../utils/date';
import React from 'react';
import { following_intl, order_cards_intl } from './termine-query';

import { useLangKey } from '../../utils/lang';

export const TermineDates = ({ date }) => {
	const langKey = useLangKey();
	const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
	const { datesAreFollowing, ticketUrl, note } = date;
	return (
		<React.Fragment>
			{date.launchList.map(({ launchAt, hint }) => (
				<React.Fragment>
					<div sx={{ variant: 'text.dates', mb: 0 }}>
						{launchAt && to_locale_data(launchAt, langKey, options)}
					</div>
					<div sx={{ variant: 'text.dates', px: [0, null, 4], mt: [1, null, 0], mb: [0, null, 2], color: '#000' }}>{hint?.note}</div>
					<div sx={{ variant: 'text.dates', mb: [4, null, 0], }}>{date.location}</div>
				</React.Fragment>
			))}
			{note && <div sx={{ gridColumn: [1, null, 'span 2'], variant: 'text.dates', mt: 2 }}>{note.note}</div>}
			{datesAreFollowing && <div sx={{ gridColumn: [1, null, 'span 2'], variant: 'text.dates', color: '#ff2fac', mt: 4 }}>{following_intl[langKey]}</div>}
			{ticketUrl && <a sx={{ variant: 'text.more', gridColumn: [1, null, '3'], mt: [0, null, 4], fontSize: 4 }} href={ticketUrl}>{order_cards_intl[langKey]}</a>}
		</React.Fragment>
	);
};