/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, Link } from 'gatsby';
import { Themed } from 'theme-ui';
import { useLangKey } from '../../utils/lang';
import Layout from '../../gatsby-theme-catalyst-core/components/layout';
import React, { useState } from 'react';
import More from '../more';
import { useSetHover, MoreHoverContextProvider } from '../MoreHoverContext';
import { TermineDates } from './TermineDates';
// import { BreakPoint } from '../group-page/BreakPoint';
import ContentContainer from '../../gatsby-theme-catalyst-core/components/content-container';

export const order_cards_intl = {
	de: 'Karten Bestellen',
	fr: 'COMMANDER UN BILLET',
	en: 'Order tickets',
	es: 'Solicitar boletos'
};
export const following_intl = {
	de: 'Weitere Termine folgen',
	fr: 'Autres dates à suivre',
	en: 'More dates to follow',
	es: 'Otras fechas a seguir'
};

const Heading = ({ name, theater, slug }) => {
	const setHover = useSetHover();
	return (
		<div
			sx={{
				gridColumn: [ 1, null, 'span 3' ],
				mb: 3,
				mt: 5
			}}
		>
			<Link
				sx={{
					textDecoration: 'none',
					display: [ 'grid', null, 'block' ],
					gridTemplateRows: [ 'auto auto', null, 'auto' ]
				}}
				to={slug}
			>
				<Themed.h2
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					sx={{ display: 'inline', lineHeight: '1.5em' }}
				>
					{name} {theater && `- ${theater}`}
				</Themed.h2>
				<More
					msx={{
						ml: [ 0, null, 4 ],
						bottom: '5px',
						pt: '4px',
						pb: '5px',
						paddingLeft: '10px',
						paddingRight: '5px'
					}}
				/>
			</Link>
		</div>
	);
};

const Dates = ({ date }) => {
	const langKey = useLangKey();
	const { name, slug } = date.play.playIntl; //get_by_langKey(date.play.playIntl, 'name', langKey);
	const { theater } = date;

	return (
		<React.Fragment>
			<MoreHoverContextProvider>
				<Heading name={name} langKey={langKey} theater={theater} slug={`/${langKey}/${slug}`} />
			</MoreHoverContextProvider>
			<TermineDates date={date} />
		</React.Fragment>
	);
};

const TerminePage = ({ location, pageContext, data }) => {
	const dates = data.allSanityDatesPage.nodes[0].dates;
	//const handleClick = useLetBlink()
	return (
		<Layout pathname={location.pathname} pageContext={pageContext}>
			<ContentContainer>
				<div
					sx={{
						display: 'grid',
						gridTemplateColumns: [ 'auto', null, 'auto auto auto' ],
						maxWidth: '1100px',
						mx: 'auto'
					}}
				>
					{dates.map((date) => <Dates date={date} />)}
				</div>
			</ContentContainer>
			{/* <BreakPoint /> */}
		</Layout>
	);
};

export const query = graphql`
	query DatesPageQuery($langCode: String!) {
		allSanityDatesPage {
			nodes {
				dates {
					play {
						playIntl {
							name(langCode: $langCode)
							slug(langCode: $langCode)
						}
					}
					ticketUrl
					note {
						note(langCode: $langCode)
					}
					launchList {
						launchAt
						hint {
							note(langCode: $langCode)
						}
					}
					theater
					location
					datesAreFollowing
				}
			}
		}
	}
`;

export default TerminePage;
